import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const SubNavBar = ({ categories, activeCategory, setActiveCategory }) => {
  return (
    <div className="bg-gradient-to-r from-pink-200 to-blue-200 shadow-md sticky top-0 z-10">
      <nav className="container mx-auto px-4 py-4">
        <ul className="flex flex-wrap justify-center space-x-4">
          {categories.map((category, index) => (
            <li key={index}>
              <button
                onClick={() => setActiveCategory(category)}
                className={`px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
                  activeCategory === category
                    ? 'bg-white text-blue-500'
                    : 'text-gray-800 hover:bg-white hover:text-blue-500'
                }`}
              >
                {category}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

const ServiceCategory = ({ title, services, id }) => (
  <div id={id} className="mb-16 scroll-mt-16">
    <h2 className="text-3xl sm:text-4xl font-bold mb-8 font-serif text-center text-gray-800">{title}</h2>
    <div className={`grid ${services.length === 1 ? 'grid-cols-1 max-w-2xl mx-auto' : 'grid-cols-1 md:grid-cols-2'} gap-8`}>
      {services.map((service, index) => (
        <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 border border-gray-100" ref={service.ref}>
          <h3 className="text-xl font-semibold mb-3 text-blue-600">{service.name}</h3>
          <p className="mb-4 text-gray-600">{service.description}</p>
          {service.areas && (
            <div className="mt-4 bg-gray-50 p-4 rounded-md">
              <h4 className="font-semibold text-gray-800 mb-2">Available Areas:</h4>
              <ul className="list-disc list-inside space-y-1">
                {service.areas.map((area, idx) => (
                  <li key={idx} className="text-gray-700">{area}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="mt-4 bg-gray-50 p-4 rounded-md">
            <p className="font-semibold text-gray-800">Pricing: <span className="text-blue-600">Call for Price</span></p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const Services = () => {
  const [activeCategory, setActiveCategory] = useState('');
  const location = useLocation();
  const categoriesRef = useRef({});
  const servicesRef = useRef({});

  const serviceCategories = useMemo(() => [
    {
      title: "Body Contouring",
      services: [
        {
          name: "Body Sculpting",
          description: "Non-invasive procedure using electromagnetic therapy to lose fat and build/tone muscle. 30-minute sessions, 8 sessions suggested."
        },
        {
          name: "Cavitation",
          description: "Hand-held device with radio frequency to reduce cellulite and produce collagen simultaneously. (30 min session)"
        },
        {
          name: "Vacuum therapy with Radio Frequency",
          description: "Hand-held device that creates superficial and deep heating of connective tissue fibers to release fluids in the fat cells that cause cellulite. (30 minute session)"
        },
        {
          name: "Wood therapy",
          description: "Several different hand-held tools that help improve blood and lymph circulation as well as reduce cellulite. This is currently used as an add-on only to other services. (10 minutes)"
        },
        {
          name: "Cryo T-Shock",
          description: "Versatile machine used for anti-aging as well treating localized fat. The device alternates between using hot and cold temperatures to treat localized fat, reduce cellulite, tone, and tighten crepe or loose skin. (45 min sessions)"
        },
        {
          name: "Infrared sauna blanket",
          description: "Blanket that heats up using infrared technology to sweat out impurities, burn calories, reduce stress, boost mood, and increase blood flow and circulation. (45 minute session)"
        },
        {
          name: "Vacuum therapy to breast or buttocks",
          description: "Machine that places cups on desired area to sculpt, tighten and lift area. (45 minute sessions)"
        },
        {
          name: "Micro Needling",
          description: "Minimally invasive procedure for your skin. The healthcare provider uses thin needles to make tiny holes in the top layer of your skin to help stimulate your skin's healing process to produce more collagen and elastin. This procedure can be done with or without radiofrequency to combat aging skin, cellulite, and scarring. (Timing for session depends on the area being treated)"
        }
      ]
    },
    {
      title: "Laser Hair Removal",
      services: [
        {
          name: "Laser Hair Removal",
          description: "Procedure using concentrated beam of light to destroy hair follicles.",
          areas: [
            "Face",
            "Underarms",
            "Bikini area",
            "Legs",
            "Back",
            "Other areas (call for details)"
          ]
        }
      ]
    },

    {
      title: "Back Treatments",
      services: [
        {
          name: "Back Cupping",
          description: "Uses plastic suction cups to promote loosening of soft tissue, remove toxins, and promote lymphatic flow. Sessions range from 30-45 minutes."
        },
        {
          name: "CRYO",
          description: "Handheld device using both cold and heat to aid with swelling and inflammation. 45-minute sessions."
        }
      ]
    },
    {
      title: "Skin Care",
      services: [
        {
          name: "Customized Facials",
          description: "Personalized facial designed to treat specific skin concerns such as rosacea, acne, hydration, anti-aging, brightening, and combinations."
        },
        {
          name: "Facial Peels",
          description: "Chemical solution applied to the face to remove dead skin cells and stimulate growth of new cells. A consultation to review home care and facial treatments done prior are required."
        },
        {
          name: "Micro needling to the face",
          description: "Minimally invasive procedure using thin needles to make holes in the top layer of the skin. The damage helps stimulate the healing process to build collagen and elastin. This treatment is useful for anti-aging, acne scars, hyperpigmentation, and sun damage. (sessions are approx. 30 min)"
        },
        {
          name: "Fibro blasting",
          description: "Therapy using a pen-like device that discharges high-frequency electric current to small areas of the skin. The hot current creates small holes, or micro-injuries in the skin's layer. This treatment will result in tighter, firmer, smoother skin as well as decrease fine lines and wrinkles. (Sessions include 30 minutes to numb and approx. 30-45 min for the procedure)"
        },
        {
          name: "Dermaplaning",
          description: "An exfoliation treatment that involves gently scraping the face with a medical grade scalpel to remove the very top layer of the skin. The shave removes both dead skin cells and the fine vellus hairs leaving the surface of the face smooth. (Session takes approx. 15 min)"
        },
        {
          name: "Customized Back Facial",
          description: "Uses Products to address your skin's needs. (Session is approx. 45 minutes)"
        }
      ]
    }
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToElement = (element) => {
    if (element) {
      const navbarHeight = 64; 
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - navbarHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  const scrollToCategory = (category) => {
    setActiveCategory(category);
    const element = categoriesRef.current[category];
    scrollToElement(element);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 100; 

      for (const category of serviceCategories) {
        const element = categoriesRef.current[category.title];
        if (element && element.offsetTop <= scrollPosition && element.offsetTop + element.offsetHeight > scrollPosition) {
          setActiveCategory(category.title);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [serviceCategories]);

  useEffect(() => {
    if (location.hash) {
      const hash = decodeURIComponent(location.hash.slice(1));
      const [category, service] = hash.split('/');
      setActiveCategory(category);
      
      setTimeout(() => {
        let element;
        if (service) {
          // Find the correct service element
          for (const cat of serviceCategories) {
            if (cat.title === category) {
              const serviceObj = cat.services.find(s => s.name === service);
              if (serviceObj) {
                element = servicesRef.current[serviceObj.name];
                break;
              }
            }
          }
        } else {
          element = categoriesRef.current[category];
        }
        if (element) {
          scrollToElement(element);
        }
      }, 100);
    } else {
      // If there's no hash, scroll to top
      window.scrollTo(0, 0);
    }
  }, [location, serviceCategories]);

  return (
    <div className="bg-gray-50">
      <div className="bg-gradient-to-r from-pink-300 to-blue-300">
        <div className="container mx-auto px-4 pt-12 pb-6">
          <h1 className="text-5xl sm:text-6xl font-bold mb-4 text-center font-serif text-gray-800">
            Our Services
          </h1>
        </div>
      </div>
      <SubNavBar 
        categories={serviceCategories.map(cat => cat.title)}
        activeCategory={activeCategory}
        setActiveCategory={scrollToCategory}
      />
      <div className="container mx-auto px-4 py-12">
        {serviceCategories.map((category, index) => (
          <div 
            key={index} 
            ref={el => categoriesRef.current[category.title] = el}
          >
            <ServiceCategory 
              title={category.title} 
              services={category.services.map(service => ({
                ...service,
                ref: el => servicesRef.current[service.name] = el
              }))} 
              id={category.title}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;