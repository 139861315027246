import React from 'react'
import { Link } from 'react-router-dom'
import heroImage from './bg.png'

const Hero = () => {
  return (
    <div className="relative w-full bg-cover bg-center" style={{ backgroundImage: `url(${heroImage})`, height: '70vh' }}>
      <div className="absolute inset-0 bg-gray-800 bg-opacity-60"></div>
      <div className="relative z-10 flex items-center justify-center h-full">
        <div className="text-center text-white p-8">
          <h1 className="text-5xl font-bold mb-4 font-serif">Welcome to Sweet n Salty Body Works</h1>
          <Link 
            to="/booking" 
            className="inline-block bg-blue-500 text-white px-6 py-3 rounded-full text-lg font-medium font-serif hover:bg-pink-300 transition duration-300"
          >
            Book an Appointment
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero
