import React, { useEffect } from 'react';
import BA2 from './BA2.jpg';
import BA3 from './BA3.jpeg';
import BA4 from './BA4.png';
import BA5 from './BA5.jpg';
import BA6 from './BA6.jpg';
import BA7 from './BA Chemical Peel.jpeg';

const BeforeAfterCard = ({ image, service, description }) => (
  <div className="bg-white rounded-lg shadow-lg overflow-hidden">
    <div className="h-64 overflow-hidden">
      <img 
        src={image} 
        alt={`Before and after ${service}`} 
        className="w-full h-full object-cover"
      />
    </div>
    <div className="p-6">
      <h3 className="text-xl font-semibold mb-2 text-blue-600">{service}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const BeforeAfter = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const beforeAfterData = [
    {
      image: BA2,
      service: "Body Sculpting",
      description: "Client underwent 5 minutes of Wood Therapy, then Cavitites with RF followed by vacuum suction with RF, followed by body sculpting using electro magnetic therapy."
    },
    {
      image: BA3,
      service: "Fibroblast",
      description: "Client recieved Fibroblast around the Eye area."
    },
    {
      image: BA4,
      service: "Body Sculpting",
      description: "Client underwent wood therapy and RF with activation and suction, followed by electromagnetic therapy."
    },
    {
      image: BA5,
      service: "Cyro T shock",
      description: "Client did Cryo T Shock followed by body sculpting machine using electromagnetic therapy."
    },
    {
      image: BA6,
      service: "Microneedling",
      description: "Clients skin shows improvement in texture and tone after Microneedling."
    },
    {
      image: BA7,
      service: "Chemical Peel",
      description: "Client's skin shows significant improvement in texture and tone after Chemical Peel treatment."
    }
  ];

  return (
    <div className="bg-gray-50">
      <div className="bg-gradient-to-r from-pink-300 to-blue-300">
        <div className="container mx-auto px-4 pt-12 pb-6">
          <h1 className="text-5xl sm:text-6xl font-bold mb-4 text-center font-serif text-gray-800">
            Before and After
          </h1>
        </div>
      </div>
      <div className="container mx-auto px-4 py-12">
        <p className="text-xl text-center text-gray-600 mb-12">
          See the amazing results our clients have achieved with our services.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {beforeAfterData.map((item, index) => (
            <BeforeAfterCard key={index} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BeforeAfter;