import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ownerImage from './ANN.jpg'; 

const About = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#top') {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-gradient-to-r from-pink-300 to-blue-300 p-8 rounded-lg shadow-lg mb-12">
        <h1 className="text-4xl sm:text-5xl font-bold mb-4 text-center font-serif text-gray-800">
          About Sweet n Salty Body Works
        </h1>
      </div>
      
      <section className="mb-12">
        <h2 className="text-2xl sm:text-3xl font-semibold mb-6 font-serif">Meet the Owner</h2>
        <div className="bg-white p-6 rounded-lg shadow-md flex flex-col sm:flex-row items-center">
          <img 
            src={ownerImage} 
            alt="Ann Rivera" 
            className="w-40 h-40 rounded-full mb-4 sm:mb-0 sm:mr-6 flex-shrink-0 object-cover"
          />
          <div className="text-center sm:text-left">
            <h3 className="text-2xl font-semibold mb-2">Ann Rivera</h3>
            <p className="text-gray-600 mb-4">Founder & Lead Medical Aesthetician</p>
            <p className="text-lg">
              Ann founded Sweet n Salty Body Works with a vision to combine advanced medical aesthetics with a spa-like experience. 
              With extensive training in laser treatments, body contouring, and other cutting-edge procedures, Ann personally 
              oversees all aspects of the medical spa to ensure each client receives the highest quality care and achieves their desired results.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl sm:text-3xl font-semibold mb-4 font-serif">Our Story</h2>
        <p className="text-lg mb-4">
          Established in 2021, Sweet n Salty Body Works began as a state-of-the-art medical spa with a mission to provide 
          advanced aesthetic treatments in a welcoming, luxurious environment. We've quickly grown to become a trusted name 
          in the community, known for our expertise in laser treatments, body contouring, and other non-invasive cosmetic procedures.
        </p>
        <p className="text-lg">
          Our journey has been marked by a commitment to staying at the forefront of aesthetic technology. We continuously 
          invest in the latest equipment and training to offer our clients the most effective and innovative treatments available.
        </p>
      </section>

      <section className="mb-12 bg-blue-100 p-6 rounded-lg">
        <h2 className="text-2xl sm:text-3xl font-semibold mb-4 font-serif">Our Mission</h2>
        <p className="text-lg">
          At Sweet n Salty Body Works, our mission is to empower our clients to look and feel their best through advanced 
          medical aesthetic treatments. We are committed to:
        </p>
        <ul className="list-disc list-inside mt-4 space-y-2 text-lg">
          <li>Providing cutting-edge, non-invasive cosmetic procedures</li>
          <li>Ensuring a safe, comfortable, and luxurious environment for all our clients</li>
          <li>Staying updated with the latest advancements in medical aesthetics</li>
          <li>Offering personalized treatment plans tailored to each client's unique needs and goals</li>
          <li>Maintaining the highest standards of professionalism and medical ethics</li>
        </ul>
      </section>
    </div>
  );
};

export default About;