import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const API_URL = process.env.REACT_APP_API_URL || (window.location.hostname === 'localhost' ? 'http://localhost:5000' : '');
      const response = await fetch(`${API_URL}/api/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      // Check if the response is JSON
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const data = await response.json();
        
        if (!response.ok) {
          throw new Error(data.message || 'An error occurred');
        }

        setSubmitMessage('Thank you for your message. We will get back to you soon!');
        setFormData({ name: '', email: '', message: '' });
      } else {
        // If the response is not JSON, read it as text
        const textData = await response.text();
        console.error('Received non-JSON response:', textData);
        throw new Error('Received an invalid response from the server');
      }
    } catch (error) {
      console.error('Error:', error);
      setSubmitMessage(error.message || 'An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          className="block w-full px-4 py-3 rounded-md bg-gray-50 border border-gray-300 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out"
        />
      </div>
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          className="block w-full px-4 py-3 rounded-md bg-gray-50 border border-gray-300 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out"
        />
      </div>
      <div>
        <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">Message</label>
        <textarea
          id="message"
          name="message"
          rows="4"
          value={formData.message}
          onChange={handleChange}
          required
          className="block w-full px-4 py-3 rounded-md bg-gray-50 border border-gray-300 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out"
        ></textarea>
      </div>
      <div>
        <button 
          type="submit" 
          className="w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Sending...' : 'Send Message'}
        </button>
      </div>
      {submitMessage && (
        <div className={`mt-2 text-sm ${submitMessage.includes('error') ? 'text-red-600' : 'text-green-600'}`}>
          {submitMessage}
        </div>
      )}
    </form>
  );
};

const MapComponent = () => {
  const position = [26.318906403364576, -80.11029908754753];

  return (
    <MapContainer center={position} zoom={17} style={{ height: '400px', width: '100%' }} className="rounded-lg shadow-md">
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position}>
        <Popup>
          Sweet n Salty Body Works <br /> 10 Fairway Drive, Deerfield Beach,Fl Suite 224
        </Popup>
      </Marker>
    </MapContainer>
  );
};

const Contact = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#top' || !location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div className="bg-gray-50">
      <div className="bg-gradient-to-r from-pink-300 to-blue-300">
        <div className="container mx-auto px-4 pt-12 pb-6">
          <h1 className="text-5xl sm:text-6xl font-bold mb-4 text-center font-serif text-gray-800">
            Contact Us
          </h1>
        </div>
      </div>
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl sm:text-3xl font-semibold mb-6 font-serif text-gray-800">Send us a message</h2>
            <ContactForm />
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl sm:text-3xl font-semibold mb-6 font-serif text-gray-800">Contact Information</h2>
            <div className="space-y-4 text-lg text-gray-600">
              <p>
                <strong className="text-gray-800">Address:</strong> 10 Fairway Drive, Deerfield Beach,Fl Suite 224
              </p>
              <p>
                <strong className="text-gray-800">Phone:</strong> (954) 482-3734
              </p>
              <p>
                <strong className="text-gray-800">Email:</strong> relax@sasbodyworks.com
              </p>
              <p>
                <strong className="text-gray-800">Hours:</strong><br />
                Monday - Friday: 9am - 5pm<br />
                Saturday, Sunday: Closed
              </p>
            </div>
          </div>
        </div>
        <div className="mt-12">
          <h2 className="text-2xl sm:text-3xl font-semibold mb-6 font-serif text-gray-800">Location</h2>
          <MapComponent />
        </div>
      </div>
    </div>
  );
};

export default Contact;