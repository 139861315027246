import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Booking = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: '',
  });
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#top') {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setMessageType('');
  
    try {
      const API_URL = process.env.REACT_APP_API_URL || (window.location.hostname === 'localhost' ? 'http://localhost:5000' : '');
      const response = await fetch(`${API_URL}/api/booking`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      // Check if the response is JSON
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const data = await response.json();
        
        if (!response.ok) {
          throw new Error(data.message || 'An error occurred');
        }
        
        console.log('Success:', data);
        setMessage('Appointment booked successfully! You will be contacted soon.');
        setMessageType('success');
        setFormData({
          name: '',
          email: '',
          phone: '',
          service: '',
        });
      } else {
        // If the response is not JSON, read it as text
        const textData = await response.text();
        console.error('Received non-JSON response:', textData);
        throw new Error('Received an invalid response from the server');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage(error.message || 'Error booking appointment. Please try again.');
      setMessageType('error');
    }
  };

  return (
    <div className="bg-gray-50">
      <div className="bg-gradient-to-r from-pink-300 to-blue-300">
        <div className="container mx-auto px-4 pt-12 pb-6">
          <h1 className="text-5xl sm:text-6xl font-bold mb-4 text-center font-serif text-gray-800">
            Book an Appointment
          </h1>
        </div>
      </div>
      <div className="container mx-auto px-4 py-12">
        {message && (
          <p className={`mb-6 text-center text-lg ${
            messageType === 'success' ? 'text-green-600' : 'text-red-600'
          }`}>
            {message}
          </p>
        )}
        <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg">
          <div className="mb-6">
            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-700">Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="service" className="block mb-2 text-sm font-medium text-gray-700">Service</label>
            <select
              id="service"
              name="service"
              value={formData.service}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="" disabled>Select a service</option>
              <option value="multiple-services" style={{ fontWeight: 'bold' }}>2 OR MORE SERVICES</option>
              <optgroup label="Body Contouring">
                <option value="body-sculpting">Body Sculpting</option>
                <option value="cavitation">Cavitation</option>
                <option value="vacuum-therapy-rf">Vacuum therapy with Radio Frequency</option>
                <option value="wood-therapy">Wood therapy</option>
                <option value="cryo-t-shock">Cryo T-shock</option>
                <option value="infrared-sauna-blanket">Infrared sauna blanket</option>
                <option value="vacuum-therapy-breast-buttocks">Vacuum therapy to breast or buttocks</option>
                <option value="micro-needling-body">Micro needling (Body)</option>
              </optgroup>
              <optgroup label="Laser Hair Removal">
                <option value="laser-hair-removal">Laser Hair Removal</option>
              </optgroup>
              <optgroup label="Back Treatments">
                <option value="back-cupping">Back Cupping</option>
                <option value="cryo-back">CRYO (Back)</option>
              </optgroup>
              <optgroup label="Skin Care">
                <option value="customized-facials">Customized Facials</option>
                <option value="facial-peels">Facial Peels</option>
                <option value="micro-needling-face">Micro needling (Face)</option>
                <option value="fibro-blasting">Fibro blasting</option>
                <option value="dermaplaning">Dermaplaning</option>
                <option value="customized-back-facial">Customized Back Facial</option>
              </optgroup>
            </select>
          </div>
          <button type="submit" className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-300">
            Book Appointment
          </button>
        </form>
      </div>
    </div>
  );
};

export default Booking;