import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../Components/Navbar/12.png';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-500 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="footer-logo">
            <img src={logo} alt="Logo" className="w-40 h-40 object-contain" />
          </div>
          <div className="footer-info">
            <h4 className="text-xl font-bold mb-4">Sweet n Salty Body Works</h4>
            <p>10 Fairway Drive, Deerfield Beach,Fl Suite 224</p>
            <p>Phone: (954) 482-3734</p>
            <p>Email: relax@sasbodyworks.com</p>
            <p>Office Hours: Mon-Fri | 9am - 5pm</p>
            <p>*APPOINTMENT ONLY*</p>
          </div>
          <div className="quick-links">
            <h4 className="text-xl font-bold mb-4">Quick Links</h4>
            <ul>
              <li><Link to="/services#top" className="hover:text-gray-300">Services</Link></li>
              <li><Link to="/about#top" className="hover:text-gray-300">About Us</Link></li>
              <li><Link to="/contact#top" className="hover:text-gray-300">Contact</Link></li>
              <li><Link to="/booking#top" className="hover:text-gray-300">Book Appointment</Link></li>
            </ul>
          </div>
          <div className="newsletter">
            <h4 className="text-xl font-bold mb-4">Subscribe to Our Newsletter</h4>
            <form className="flex flex-col sm:flex-row">
              <input
                type="email"
                placeholder="Enter your email"
                className="bg-gray-700 text-white p-2 mb-2 sm:mb-0 sm:mr-2"
              />
              <button type="submit" className="bg-blue-500 text-white p-2 hover:bg-blue-600">
                Subscribe
              </button>
            </form>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 flex flex-col sm:flex-row justify-between items-center">
          <div className="copyright order-2 sm:order-1 mt-4 sm:mt-0">
            &copy; {new Date().getFullYear()} Sweet n Salty Body Works. All rights reserved.
          </div>
          <div className="social-icons flex space-x-4 order-1 sm:order-2">
            <a href="https://www.facebook.com/profile.php?id=100077993940572" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400 transition duration-300">
              <FaFacebookF size={24} />
            </a>
            <a href="https://www.instagram.com/sasbodyworks/" target="_blank" rel="noopener noreferrer" className="hover:text-pink-400 transition duration-300">
              <FaInstagram size={24} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
