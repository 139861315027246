import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import NavBar from './Components/Navbar/NavBar';
import Hero from './Components/Hero/Hero';
import Home from './Components/Home/Home'; 
import About from './Components/About/About'; 
import Services from './Components/Services'; 
import Contact from './Components/Contact'; 
import Footer from './Components/Footer';
import BeforeAfter from './Components/B&A/BeforeAfter';
import Booking from './Components/Booking';
import './App.css'; 

function App() {
  return (
    <Router>
      <div>
        <NavBar />
        <MainContent />
        <Footer />
      </div>
    </Router>
  );
}

function MainContent() {
  const location = useLocation();
  return (
    <div>
      {location.pathname === '/' && <Hero />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/before-after" element={<BeforeAfter />} />
        <Route path="/booking" element={<Booking />} />
      </Routes>
    </div>
  );
}

export default App;


