import React from 'react';
import { Link } from 'react-router-dom';
import aboutImage from './aboutImage.jpg';
import laserHairRemovalImage from './laser-hair-removal.jpeg';
import microNeedlingImage from './micro-needling.jpg';
import cryoTShockImage from './cryo-t-shock.jpg';
import beforeAfterImage from '../B&A/BA2.jpg';

const Home = () => {
  const services = [
    { name: 'Laser Hair Removal', image: laserHairRemovalImage, slug: 'Laser Hair Removal' },
    { name: 'Micro Needling', image: microNeedlingImage, slug: 'Body Contouring/Micro Needling' },
    { name: 'Cryo T-Shock', image: cryoTShockImage, slug: 'Body Contouring/Cyro T-shock' },
  ];

  return (
    <div className="container mx-auto px-4 py-8 font-serif">
      <div className="about-section flex flex-col lg:flex-row gap-8 mb-12">
        <div className="about-text lg:w-2/3">
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-6">About Us</h2>
          <p className='text-base sm:text-lg lg:text-xl'>
          Sweet n Salty Body Works is a premier medical spa offering advanced aesthetic treatments and luxurious body contouring services. 
          Our team of skilled medical professionals utilizes cutting-edge technology and evidence-based techniques to deliver exceptional results. 
          From laser treatments to non-invasive body sculpting, we provide personalized care in a serene, spa-like environment. 
          Experience the perfect blend of medical expertise and indulgent relaxation at Sweet n Salty Body Works.
          </p>
        </div>
        <div className="about-image lg:w-1/3">
          <img src={aboutImage} alt="About us" className="rounded-lg shadow-lg w-full h-64 object-cover" />
        </div>
      </div>

      <div className="services-section bg-blue-100 py-12 rounded-lg mb-12">
        <h2 className="text-3xl sm:text-4xl font-bold text-center mb-10">Our Most Popular Services</h2>
        <div className="services-container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {services.map((service, index) => (
            <Link 
              key={index} 
              to={`/services#${encodeURIComponent(service.slug)}`} 
              className="service-card relative rounded-lg shadow-md overflow-hidden aspect-square cursor-pointer transition-transform duration-300 hover:scale-105"
            >
              <img src={service.image} alt={service.name} className="w-full h-full object-cover" />
              <div className="absolute inset-0 bg-black bg-opacity-40 hover:bg-opacity-30 transition-opacity duration-300 flex items-center justify-center">
                <h3 className="text-2xl font-semibold text-center text-white px-4">{service.name}</h3>
              </div>
            </Link>
          ))}
        </div>
        <div className="text-center mt-12">
          <Link 
            to="/services" 
            className="inline-block bg-blue-500 text-white py-3 px-6 rounded-lg text-lg hover:bg-pink-400 transition duration-300"
          >
            View All Services
          </Link>
        </div>
      </div>

      <div className="before-after-section bg-pink-100 py-12 rounded-lg">
        <h2 className="text-3xl sm:text-4xl font-bold text-center mb-10">See Our Results</h2>
        <div className="before-after-preview max-w-4xl mx-auto">
          <img src={beforeAfterImage} alt="Before and After" className="w-full h-64 object-cover rounded-lg shadow-md mb-8" />
          <p className="text-center text-lg mb-8">
            Discover the amazing transformations our clients have experienced with our services.
          </p>
        </div>
        <div className="text-center">
          <Link 
            to="/before-after" 
            className="inline-block bg-pink-500 text-white py-3 px-6 rounded-lg text-lg hover:bg-blue-500 transition duration-300"
          >
            View Before & After Gallery
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;