import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './12.png'
import { Menu, X } from 'lucide-react'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white font-serif shadow-md">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
        <div className="flex items-center flex-shrink-0 -ml-6 md:-ml-7">
            <img src={logo} alt="SAS Logo" className="h-28 w-auto object-contain" />
          </div>
          <div className="hidden md:flex items-center space-x-8">
            <NavLink to="/">Home</NavLink>
            <NavLink to="/about">About</NavLink>
            <NavLink to="/services">Services</NavLink>
            <NavLink to="/before-after">Before & After</NavLink>
            <NavLink to="/contact">Contact</NavLink>
            <Link 
              to="/booking" 
              className="bg-blue-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-pink-400 transition duration-300 ease-in-out"
            >
              Book Now
            </Link>
          </div>
          <div className="md:hidden mr-2">
            <button onClick={toggleMenu} className="text-gray-900 hover:text-blue-400 p-2">
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <MobileNavLink to="/" onClick={toggleMenu}>Home</MobileNavLink>
            <MobileNavLink to="/about" onClick={toggleMenu}>About</MobileNavLink>
            <MobileNavLink to="/services" onClick={toggleMenu}>Services</MobileNavLink>
            <MobileNavLink to="/before-after" onClick={toggleMenu}>Before and After</MobileNavLink>
            <MobileNavLink to="/contact" onClick={toggleMenu}>Contact</MobileNavLink>
            <Link 
              to="/booking" 
              onClick={toggleMenu}
              className="block text-center bg-blue-500 text-white px-3 py-2 rounded-md text-base font-medium hover:bg-pink-400 transition duration-300 ease-in-out"
            >
              BOOK NOW
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

const NavLink = ({ to, children }) => (
  <Link 
    to={to} 
    className="text-gray-900 inline-flex items-center px-1 pt-1 text-sm font-medium hover:text-blue-400 transition duration-300 ease-in-out border-b-2 border-transparent hover:border-blue-400"
  >
    {children}
  </Link>
);

const MobileNavLink = ({ to, children, onClick }) => (
  <Link 
    to={to} 
    onClick={onClick}
    className="text-gray-900 block px-3 py-2 text-base font-medium hover:text-blue-400 transition duration-300 ease-in-out"
  >
    {children}
  </Link>
);

export default Navbar;